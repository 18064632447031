import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"

function StaticCurtain(props) {
  let dyStart = -2.75
  let dyStartMobile = -3.5
  // if (props.post.svgHeading.length === 4) {
  //   dyStart = -3.5;
  // }
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1 id="SEOTitle">{props.post.seoHeading}</h1>
      <div className="mobile-headline">
        <h2 className="h1-style">{props.post.mobileHeading}</h2>
      </div>
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset" }}
      >
        <svg className="sizzleSVG is-hidden-mobile" viewBox="0 0 100 1">
          <text
            x="50"
            dominantBaseline="middle"
            textAnchor="middle"
            id="sizzleText"
          >
            {props.post.svgHeading.map((heading, i) => (
              <tspan
                key={heading}
                className={`svg-text-${i + 1}`}
                id={`sizzle-svg-text-${i + 1}`}
                x="50"
                y="50%"
                fill="#1b559a"
                color="#1b559a"
                dy={`${dyStart + i * 1.08}em`}
              >
                {heading}
              </tspan>
            ))}
          </text>
        </svg>
        <svg className="sizzleSVG mobile-sizzleSVG is-hidden-tablet" viewBox="0 0 100 1">
          <text
            x="50"
            dominantBaseline="middle"
            textAnchor="middle"
            id="sizzleText"
          >
            {props.post.svgHeadingMobile.map((heading, i) => (
              <tspan
                key={heading}
                className={`svg-text-${i + 1}`}
                id={`sizzle-svg-text-${i + 1}`}
                x="50"
                y="50%"
                fill="#1b559a"
                color="#1b559a"
                dy={`${dyStartMobile + i * 1.08}em`}
              >
                {heading}
              </tspan>
            ))}
          </text>
        </svg>
      </div>
      <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
        controls={true}
        playing={true}
        onlyButton={true}
        sizzleButton={true}
        buttonClass="sizzle-button-under"
        compId={"sizzleTheaterButton"}
        language={props.language}
      />
    </div>
  )
}

export default StaticCurtain
