import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"

import ImageMeta from "../../components/ImageMeta"
import "../../components/animations/scroll.scss"
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer';

function FirstTime(props) {
  if (props.leftRight) {
    return(
      <section className="home-section left-right first-time text-picture home-section-1">
      <div className="columns is-vcentered reverse-columns">
        <div className="column is-2"></div>
        <div className="column is-10 picture-column is-hidden-touch-fix">
          <div className="columns">
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId="AOMS/DEV/homepage-first-time-desktop"
                src={`https://res.cloudinary.com/nuvolum/image/upload/q_10/v1/AOMS/DEV/homepage-first-time-desktop`}
                width="auto"
                responsive
                data-aos-duration="1200"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
              />
            </div>
          </div>
        </div>
        <div className="column is-1 is-hidden-touch-fix"></div>
        <div
          data-aos="fade-up"
          data-aos-duration="200"
          id="first-column"
          className="column text-column mobile-pad"
        >
          <div className="columns">
            <div className="column">
                <MarkdownViewer 
              markdown={props.post.firstTimePatient.heading}
              priority={3}
            />
            <MarkdownViewer
              markdown={props.post.firstTimePatient.text}
            />
              <ButtonGroup tight alignLeft noReverse={props.noReverse}>
                <Button
                  contained
                  buttonText={props.post.firstTimePatient.buttonOne.buttonText}

                  href={props.post.firstTimePatient.buttonOne.href}
                />
                <Button
                  contained
                  buttonText={props.post.firstTimePatient.buttonTwo.buttonText}
                  href={props.post.firstTimePatient.buttonTwo.href}
                  
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="column is-3"></div>
      </div>
    </section>
    )
  }
  return (
    <section
      className="home-section top-down first-time color-back"
      data-aos="fade-up"
      data-aos-duration="1200"
    >

        <div className="columns">
          <div className="column is-4"></div>
          <div className="column">
            <div className="columns has-text-centered-tablet">
              <div className="column">
                <h3 style={{ marginBottom: "16px", marginTop: 0 }}>
                  {props.post.firstTimePatient.heading}
                </h3>
              </div>
            </div>
            <div className="columns">
              {/* <div
                className="column"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(props.post.firstTimePatient.text)
                )}
              ></div> */}
              <MarkdownViewer className="column large-p has-text-centered-tablet" markdown={props.post.firstTimePatient.text} />
            </div>
            <div className="columns">
              <div className="column">
                <ButtonGroup isCentered noReverse={props.noReverse}>
                  <Button
                    contained
                    buttonText={
                      props.post.firstTimePatient.buttonOne.buttonText
                    }
                    href={props.post.firstTimePatient.buttonOne.href}
                    
                  />
                  {props.language !== "es" && (
                    <Button
                      contained
                      buttonText={
                        props.post.firstTimePatient.buttonTwo.buttonText
                      }
                      href={props.post.firstTimePatient.buttonTwo.href}
                    />
                  )}
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="column is-4"></div>
        </div>
    </section>
  )
}

export default FirstTime
